<template>
    <div class="text-muted-lots">
        Based on your browser telling us it's currently {{ currentTimeFormatted }}
    </div>
</template>

<script>
import { DateTime } from "luxon";

export default {
    name: 'CurrentTime',
    components: {},
    props: {
        
    },
    data: function() {
        return {
            currentTimeFormatted: '',  // set this in method on interval
        }
    },
    computed: {
    },
    mounted: function() {
        setInterval(() => {
            this.setCurrentTime();
        }, 1000);
    },
    methods: {
        setCurrentTime: function() {
            let now = DateTime.local();
            this.currentTimeFormatted = now.toLocaleString(DateTime.DATETIME_HUGE);
        }
    }
}
</script>

<style>

</style>