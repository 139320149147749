<template>
        <div
            class="countdown-inner"
        >
            <div 
                v-if="isExpired"
                class="expired-text"
            >
                Times Up!  
                <br>
                See ya' Donald!
            </div>
            <div v-else>
                <div class="time-selection-wrapper">
                    <div>
                        <a href="#" @click="outputFormat = 'standard'">Standard Format</a>
                    </div>
                    <div>
                        <a href="#" @click="outputFormat = 'hours'">In Hours</a>
                    </div>
                    <div>
                        <a href="#" @click="outputFormat = 'minutes'">In Minutes</a>
                    </div>
                    <div>
                        <a href="#" @click="outputFormat = 'seconds'">In Seconds</a>
                    </div>
                </div>
                <div 
                    v-if="outputFormat === 'standard'" 
                    key="outputStandard"
                    class="countdown-time-wrapper"
                >
                    <div class="time-chunk">
                        <div class="number">{{ days }}</div>
                        <div class="units">Days</div>
                    </div>
                    <div class="time-chunk">
                        <div class="number">{{ hours }}</div>
                        <div class="units">Hours</div>
                    </div>
                    <div class="time-chunk">
                        <div class="number">{{ minutes }}</div>
                        <div class="units">Minutes</div>
                    </div>
                    <div class="time-chunk">
                        <div class="number">{{ seconds }}</div>
                        <div class="units">Seconds</div>
                    </div>
                </div>
                <div 
                    v-else-if="outputFormat === 'hours'" 
                    key="outputHours"
                    class="countdown-time-wrapper"
                >
                    <div class="time-chunk total-time total-hours">
                        <div class="number">{{ totalHours }}</div>
                        <div class="units">Total Hours</div>
                    </div>
                </div>
                <div 
                    v-else-if="outputFormat === 'minutes'" 
                    key="outputMinutes"
                    class="countdown-time-wrapper"
                >
                    <div class="time-chunk total-time total-minutes">
                        <div class="number">{{ totalMinutes }}</div>
                        <div class="units">Total Minutes</div>
                    </div>
                </div>
                <div 
                    v-else-if="outputFormat === 'seconds'" 
                    key="outputSeconds"
                    class="countdown-time-wrapper"
                >
                    <div class="time-chunk total-time total-seconds">
                        <div class="number">{{ totalSeconds }}</div>
                        <div class="units">Total Seconds</div>
                    </div>
                </div>
                <div class="snarky-comment">
                    Yeah... way too much.
                </div>
            </div>
        </div>
</template>

<script>
import { DateTime } from "luxon";
//import CurrentTime from './CurrentTime.vue';

export default {
    name: '',
    components: {
        //CurrentTime,
    },
    props: {
        
    },
    data: function() {
        return {
            days: null,  // set this from a method
            hours: null,
            minutes: null,
            seconds: null,
            totalHours: null,
            totalMinutes: null,
            totalSeconds: null,
            outputFormat: 'standard',  // set this when you click on different links
        }
    },
    computed: {
        currentTime: function() {
            return DateTime.local();
        },
        isExpired: function() {  // are we past the inauguration date?
            return this.currentTime > this.inaugDate;
            //return true;
        },
        inaugDate: function() {  // returns a luxon DateTime
            // luxon DateTime for inauguration date
            let inaugDateObj = {
                year: 2021,
                month: 1,
                day: 20,
                hour: 12,
                minute: 0,
                zone: 'America/New_York',
            };
            return DateTime.fromObject(inaugDateObj);
        },
    },
    mounted: function() {
        setInterval(() => {
            this.setDuration();
        }, 1000);
    },
    methods: {
        setDuration: function() {  // returns an object with keys days/hours/minutes
            let { days, hours, minutes, seconds } = this.inaugDate.diffNow(['days', 'hours', 'minutes', 'seconds']).toObject();
            this.days = days;
            this.hours = hours;
            this.minutes = minutes;
            this.seconds = Math.floor(seconds);  // old was seconds.toFixed(0);
            let totalHoursObj = this.inaugDate.diffNow(['hours']).toObject();
            this.totalHours = totalHoursObj.hours.toFixed(1);
            let totalMinutesObj = this.inaugDate.diffNow(['minutes']).toObject();
            this.totalMinutes = totalMinutesObj.minutes.toFixed(0);
            let totalSecondsObj = this.inaugDate.diffNow(['seconds']).toObject();
            this.totalSeconds = totalSecondsObj.seconds.toFixed(0);
        }
    }
}
</script>

<style>
.time-selection-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
}
.time-selection-wrapper > div {
    padding: 5px 20px;
    text-align: center;
    width: 100px;
    /* display: inline-block;
    background-color: #abc;
    border: 1px solid transparent;
    border-radius: 5px;
    text-decoration: none;
    padding: 5px 10px; */
}
.time-selection-wrapper a:hover {
    /* text-decoration: none;
    border: 1px solid #345;
    background-color: #345;
    color: #fff; */
} 
.countdown-time-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}
.time-chunk {
    text-align: center;
    margin: 0 20px 1rem 20px;
    width: 200px;
}
.time-chunk.total-time {
    width: 100%;
}
.time-chunk .number {
    font-size: 150px;
}
.time-chunk .units {
    text-transform: uppercase;
    letter-spacing: 0.08rem;
    color: red;
}
.snarky-comment {
    margin-top: 1rem;
    font-size: 1.3rem;
    text-align: center;
    color: #777;
}
.expired-text {
    font-size: 80px;
    text-align: center;
}

@media (max-width: 1000px) {
    .time-selection-wrapper > div {
        width: 50%;
    }
    .time-chunk .number {
        font-size: 100px;
    }
    .time-chunk.total-time.total-seconds .number {
        font-size: 80px;
    }
}
@media (max-width: 600px) {
    .time-chunk .number {
        font-size: 80px;
    }
    .time-chunk.total-time.total-seconds .number {
        font-size: 70px;
    }
}

</style>