<template>
    <div id="app">
        <div id="content">
            <div id="text-content">
                <div id="text-content-inner">
                    <h6 class="main-title">
                        <span class="main-title-dash">&mdash;</span>
                        The Trump Clock
                        <span class="main-title-dash">&mdash;</span>
                    </h6>
                    <h1>How much time until The Donald is out of office?</h1>
                </div>
                
            </div>
            <div id="countdown-content">
                <countdown-timer
                    class="countdown-outer"
                ></countdown-timer>
            </div>
            <div id="bottom-content">
                <current-time
                    class="footer-text"
                    style="text-align: left;"
                ></current-time>
                <div class="footer-image-box">
                    <img src="./assets/donald-trump-countdown.svg" alt="The Trump Clock - Inauguration Countdown">
                </div>
                <div 
                    class="footer-text" 
                    style="text-align: right;"
                >
                    <span class="text-muted-lots">
                        * NOTE:  Time may be shorter if enough of Congress comes to their senses and impeaches & convicts this sociopath.
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CountdownTimer from './components/CountdownTimer.vue';
import CurrentTime from './components/CurrentTime.vue';

export default {
    name: 'App',
    components: {
        CountdownTimer,
        CurrentTime
    }
}
</script>

<style>
#app {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  height: 100vh;
  width: 100vw;
  padding: 0 1rem;
  box-sizing: border-box;
}
#content {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
#text-content {
    text-align: center;
    max-width: 100%;
    width: 1000px;
    display: flex;
    justify-content: center;
    align-items: center;
}
#text-content-inner {
    max-width: 600px;
}
#countdown-content {
    display: flex;
    align-items: center;
    justify-content: center;
}
#bottom-content {
    /* height: 300px; */
    width: 100%;
    padding-top: 3rem;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}
#bottom-content .footer-image-box {
    height: 300px;
    flex-basis: 0;
    flex-grow: 1;
    display: flex;
    align-items: flex-end;
    justify-content: center;
}
#bottom-content .footer-image-box img {
    height: 100%;
    width: 100%;
    display: block;
    object-fit: contain;
}
#bottom-content .footer-image-box img::after {
    content: '';
    clear: both;
    display: table;
}
#bottom-content .footer-text {
    padding: 1rem 0;
    max-width: 400px;
    flex-basis: 0;
    flex-grow: 1;
}
h1 {
    font-size: 50px;
}
h6.main-title {
    text-transform: uppercase;
    letter-spacing: 0.08rem;
    font-size: 20px;
    opacity: 0.8;
}
.main-title-dash {
    color: coral;
    margin: 0 4px;
}

@media (max-width: 1000px) {
    #bottom-content {
        flex-direction: column;
        width: 100%;
    }
    #bottom-content .footer-image-box {
        width: 100%;
        min-height: 300px;
    }
    #bottom-content .footer-image-box img {
        width: 100%;
        height: 300px;
    }
    #bottom-content .footer-text {
        text-align: center;
        max-width: 100%;
    }
}
@media (max-width: 600px) {
    h1 {
        font-size: 36px;
    }
    h6.main-title {
        margin-top: 2rem;
        margin-bottom: 2rem;
    }
}
</style>
